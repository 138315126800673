import React from "react";
import styled from "styled-components";

import { Header1, Header2 } from "../components/Text";
import { Footer } from "../components/contact/contact";
import FirebaseImage from "../components/firebaseImage";

import Layout from "../components/Layout";
import SEO from "../components/DynamicTitle";

function TitleCard() {
  return (
    <>
      <Card>
        <ImageContainer>
          <FirebaseImage
            image={`/home/background/${Math.floor(Math.random() * 5) + 1}.jpg`}
            alt="background image"
          />
        </ImageContainer>
        <TitlePart>
          <PreTitle>Share</PreTitle>
          <StyledTitle>Your Story</StyledTitle>
          <SubTitle>Recording your family history</SubTitle>
        </TitlePart>
      </Card>
    </>
  );
}

export default function Home() {
  return (
    <Layout>
      <SEO
        title="Home"
        subTitle="Share Your Story - Recording your family history"
      />
      <TitleCard />
      <Footer />
    </Layout>
  );
}

const Card = styled.section`
  display: flex;
  flex-direction: row;

  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    background-color: var(--blue);
  }
`;

const ImageContainer = styled.div`
  height: calc(200px + 20vw);
  width: 50%;

  background-color: #dadada;

  @media (max-width: 600px) {
    height: 500px;
    width: 100%;
    opacity: 0.3;
    background-color: transparent;
  }
`;

const TitlePart = styled.div`
  padding: 2vw;
  height: calc(200px + 20vw - 4vw);
  width: calc(50% - 4vw);

  background-color: var(--blue);

  display: inline-flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media (max-width: 600px) {
    height: calc(500px - 20vh);
    width: calc(100% - 4vw);
    position: absolute;
    background-color: transparent;
    padding-top: 10vh;
  }
`;

const PreTitle = styled(Header2)`
  font-family: Montserrat;
  font-size: calc(24px + 2vw);
  font-weight: 200;
`;

export const StyledTitle = styled(Header1)`
  font-family: Sacramento;
  font-size: calc(16px + 6vw);
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 4vh;

  @media (max-width: 600px) {
    font-size: calc(16px + 16vw);
  }
`;

const SubTitle = styled(Header2)`
  font-family: Montserrat;
  font-size: calc(12px + 2vw);
  font-weight: 200;

  @media (max-width: 600px) {
    font-size: calc(18px + 4vw);
  }
`;
